@import url("https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&display=swap");

.proposal-pdf-body {
  background-color: #fff;

  @media print {
    div,
    input {
      page-break-inside: avoid;
    }
    .test {
      page-break-after: always;
    }
  }
  @page {
    margin: 30px 0px;
  }

  .pdf-wrapper {
    padding: 0 40px;

    .pdf-wrapper-header {
      width: 100%;
      padding: 0px;
      border-bottom: 1px solid #e1e1e1;
      margin-bottom: 40px;

      img {
        height: 20px;
        float: right;
        margin-top: 12px;
      }
    }

    p {
      font-size: 18px !important;
    }

    .repaymentplan-info p {
      font-size: 14px !important;
      line-height: 14px;
    }

    .border-radius-20 {
      border-radius: 20px;
    }

    .service-icon {
      background-color: #f5f4f8;
      border-radius: 12px;
      padding: 10px 16px;
      font-size: 16px;
      font-family: "lateral";
      font-weight: 700;
      display: flex;
      width: 100%;
      margin-bottom: 10px;
      align-items: center;
    }
  } // End of .pdf-wrapper

  .range-services p {
    font-size: 16px !important;
  }

  .thank-you-info {
    td {
      padding-bottom: 15px;

      i {
        font-size: 24px;
      }
    }
  }

  .repayment-rows {
    padding: 5px 0;
  }
}
