@import url("https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&display=swap");

html {
  scroll-behavior: smooth;
}

.roch-proposal {
  h1 {
    font-family: "Instrument Serif";
    font-size: 50px;
    font-weight: 400;
  }

  h2 {
    font-family: "lateral", sans-serif;
    font-size: 44px;
    font-weight: 700;
  }

  h3 {
    font-family: "lateral", sans-serif;
    font-size: 30px;
    font-weight: 700;
  }

  h4 {
    font-family: "lateral", sans-serif;
    font-size: 24px;
    font-weight: 700;
  }

  h5 {
    font-family: "lateral", sans-serif;
    font-size: 16px;
    font-weight: 700;
  }

  .item-select-box,
  .group-select {
    accent-color: #1e0758;
  }

  .swal2-confirm {
    background-color: #1e0758;
    padding: 10px 14px;
    border-radius: 20px;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.16px;
    gap: 8px;

    &:hover {
      background-color: #180647 !important;
    }
  }

  .swal2-popup {
    color: #1e0758;
    width: 480px !important;
  }

  .swal2-actions {
    width: 100%;
    justify-content: right;
    padding-right: 40px;
  }

  .project-info-img {
    height: 400px;
    width: 100%;
    object-fit: cover;
    border-radius: 40px;
    filter: brightness(0.8);
  }

  .project-info-text {
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 50px;
  }

  @media only screen and (min-width: 576px) {
    .scope-section-btn {
      float: right;
    }
  }

  .scope-expand-div {
    a {
      text-decoration: none;
    }

    &.line-item-collection-header {
      float: right;
    }

    i {
      font-size: 20px;
    }

    .text-expand {
      display: none;
    }
    .text-collapse {
      display: inline;
    }
  }

  .panel-heading.collapsed {
    i.fa {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    .text-expand {
      display: inline;
    }
    .text-collapse {
      display: none;
    }
  }
}

.max-width-1280 {
  max-width: 1280px;
  margin: 0 auto;
}

.max-width-1024 {
  max-width: 1024px;
  margin: 0 auto;
}

.prop-navbar {
}
img {
  image-orientation: from-image;
}

.roch-proposal {
  &.proposal-pdf-body {
    font-size: 18px !important;
  }
  p {
    font-family: "lateral", sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
  }

  .p-thin {
    font-weight: 400 !important;
    i {
    }
  }

  hr {
    width: 100%;
    border-top: 1px solid #888;
  }
}

.prop-pt-tall {
  padding-top: 125px;
}

/*
 * a decorative divider that leaves
 * a gap on its side 
 */
.prop-item-divider {
  margin: 0 auto;
}

.prop-footer {
  background-color: #eff1f2;
}

.proposal-title {
  font-family: "Instrument Serif";
  font-size: 50px;
}

.proposal-subtitle {
  font-family: $body-font;
  font-size: 21px !important;
  font-weight: 300 !important;
}

.proposal-header-container {
  background-image: url("/img/proposal/banner-top.png");
  background-size: cover;
  padding-bottom: 150px;
  background-position: center right;
}

.about-us-header-container {
  padding-bottom: 20px;
  background-image: url("/img/proposal/prop-about-sq-min.png");
  background-position: bottom right;
  background-size: 40%;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 540px) {
  .proposal-header-container {
    padding-bottom: 400px;
    background-repeat: no-repeat;
    background-size: 875px;
    background-position: bottom right;
  }

  .about-us-header-container {
    /* padding-bottom: 330px; */
    background-image: none;
    background-repeat: no-repeat;
    background-size: 875px;
    background-position: bottom right;
  }

  .proposal-title {
    margin-top: 50px;
  }
}

.quote-totals-belt {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media only screen and (max-width: 992px) {
  .quote-totals-belt {
    > div {
      width: 100%;

      > div {
        width: 100%;
      }
    }
  }
}

/* probably a utility class */
.roch-collapse-btn {
  background-color: transparent;
  border: none;
}

[aria-expanded="false"] .prop-collapse-open {
  display: block;
}

[aria-expanded="false"] .prop-collapse-close {
  display: none;
}

[aria-expanded="true"] .prop-collapse-open {
  display: none;
}

[aria-expanded="true"] .prop-collapse-close {
  display: block;
}

.footer-icon-belt {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  min-height: 52px;

  a {
    opacity: 1;
    transition: 0.2s all ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }
}

//forgive me - we really need to start using BEM here TODO
#signatureBox {
  font-family: "Mr Dafoe", cursive;
  border-color: $blue-primary;
  font-size: 32px;
}
#terms-and-conditions {
  height: 120px;
  overflow: scroll;
}

.wrapper {
  position: relative;
  width: 400px;
  height: 200px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.signature-pad {
  width: 450px;
  height: 200px;
  background-color: #fff;
  border: 1px solid #000;
}

.what-we-do ul li:not(:first-child) {
  margin-top: 10px;
  margin-bottom: 10px;
}

.what-we-do ul li:last-child {
  margin-bottom: 0;
}

.repayment-plan-checkbox .checkbox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.repayment-plan-checkbox input:checked {
  accent-color: #1e0758;
}

/* Prevent zooming on mobile  */
input,
select,
textarea {
  font-size: 16px !important;
}

.content-gap {
  margin-bottom: 50px !important;
}

input,
select,
textarea {
  font-size: 16px !important;
}

.bg-light-blue {
  background-color: #dff3fd;
}
