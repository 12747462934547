.roch-proposal {
  background-color: #fff;

  .font-10 {
    font-size: 10px !important;
  }

  .font-12 {
    font-size: 12px !important;
  }

  .font-13 {
    font-size: 12px !important;
  }

  .font-14 {
    font-size: 14px !important;
  }

  .font-16 {
    font-size: 16px !important;
  }

  .font-18 {
    font-size: 18px !important;
  }

  .btn {
    font-size: 16px;
  }

  .border-radius-20 {
    border-radius: 20px;
  }

  .border-radius-16 {
    border-radius: 16px;
  }

  .border-radius-12 {
    border-radius: 12px;
  }

  .border-radius-8 {
    border-radius: 8px;
  }

  .btn-primary {
    background-color: #1e0758;
    padding: 10px 14px;
    border-radius: 20px;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.16px;
    gap: 8px;

    &:hover,
    &.btn-accept:hover {
      background-color: #180647 !important;
    }
  }

  .btn-secondary {
    border: 2px solid #1e0758;
    padding: 10px 14px;
    border-radius: 20px;
    text-decoration: none;
    background-color: #fff;
    color: #1e0758;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.16px;
    gap: 8px;

    &:hover {
      color: #fff;
      background-color: #1e0758;
    }
  }

  .btn-tertiary {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #4d3a7d;
    background: #fff;
    color: #1e0758;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.16px;
  }

  .btn-decline {
    border: 2px solid #f86c6b;
    padding: 10px 14px;
    border-radius: 20px;
    text-decoration: none;
    background-color: #f86c6b;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.16px;
    gap: 8px;

    &:hover {
      background-color: #df6160;
    }
  }

  .select2-container--default .select2-selection--single {
    height: 40px;
    border-radius: 12px;
    padding: 0 12px;
    font-size: 16px;
    border: 1px solid #c2cfd6 !important;
  }

  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    padding-top: 5px;
    padding-left: 0px;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow,
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    top: 5px;
  }

  .comm-nav-mob-buffer {
    .comm-bg-decoration {
      top: -48px;
      min-height: 690px;

      @include media-breakpoint-down(md) {
        min-height: unset;
      }
    }
  }

  .bg-com-dark-blue {
    background-color: $roch-com-dark-blue;
  }

  .comm-quote-container input[type="radio"] {
    opacity: unset;
    position: relative;
  }

  @media screen and (max-width: 991px) {
    .roch-residential-header-row {
      flex-direction: column-reverse;
    }
  }
  .scope_of_work {
    input[type="radio"] {
      position: relative;
      width: 16px;
    }

    label {
      display: flex;
    }

    .recommended {
      color: #fff;
      font-size: 14px;
      background-color: #fe2547;
      padding: 4px 6px;
    }

    .radio-option {
      padding: 16px 0;
      margin-bottom: 10px;
      border-radius: 12px;
    }

    .form-check-input {
      margin: 0;
    }
  }

  .line-options {
    position: relative;
    z-index: 1;
  }
}

.comm-top-nav {
  position: fixed;
  top: 0;
  z-index: 999;
  background: #fff;
  width: 100%;
  padding-bottom: 8px;
}

.commercial-sidebar-belt {
  background-color: red;

  .com-sidebar-btn[type="button"] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }
}

.roch-comm-quote-hero-bg {
  background-image: url("/img/proposal/corporate-proposal/img-header.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  @include media-breakpoint-down(md) {
    background-image: url("/img/proposal/corporate-proposal/mobile-header-bg.png");
  }
}

.service-img {
  width: 85px;
}

.comm-painting-qld {
  .comm-painting-belt {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .comm-painting-copy {
    max-width: 400px;

    @media screen and (max-width: 1617px) {
      max-width: unset;
    }
  }

  .comm-painting-hero {
    width: 637px;
    min-width: 637px;
    max-width: 637px;

    @media screen and (max-width: 1617px) {
      margin-left: auto;
      margin-right: auto;
      width: unset;
      min-width: unset;
      max-width: unset;
    }
  }

  .primary-stat {
    font-size: 32px !important;
  }

  p {
    small {
      font-size: 10px !important;
    }
  }

  .comm-painting-qld-icon-wrap {
    .about-icon-block {
      @include media-breakpoint-down(md) {
        width: 33%;
      }

      &:not(:last-child) {
        padding-bottom: 15px;
        border-bottom: 1px dashed #024677;

        @include media-breakpoint-down(md) {
          border-right: 1px dashed #024677;
          border-bottom: none;
        }
      }
    }
  }
}

.roch-commercial-testimonial {
  .embed-responsive {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  }

  p {
    font-size: 14px !important;
  }
}

.comm-nav-dropdown {
  // top: -120px !important;
  // left: -5px !important;
  border: none !important;
}

.comm-nav-menu {
  z-index: 1032;
  border-radius: 10px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  padding: 24px;

  a {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.16px;
    color: #1e0758;
    padding-bottom: 16px;
  }

  @include media-breakpoint-down(sm) {
    overflow-y: auto;
  }
}

.comm-footer-wrap {
  .blue-slice-shape {
    position: absolute;
    top: -48px;
    right: -250px;
    width: calc(100% + 250px);
    min-height: 816px;
    background-position: top right !important;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .footer-hero-text {
    font-size: 54px;
    line-height: 60px;

    @include media-breakpoint-down(lg) {
      font-size: 42px;
    }
  }

  .footer-btn {
    display: inline-block;
    padding: 15px 25px;
    color: #fff;
    font-weight: 700;
    background-color: $roch-dark-red;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  .footer-nav-bottom-belt {
    @media screen and (max-width: 1617px) {
      > div {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .footer-to-top-btn {
    margin-left: 50px;

    .fa-arrow-up {
      color: #e34735;
      border-radius: 50%;
    }

    .footer-btn-text {
      padding: 3px 10px;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 10px;
    }
  }
}

.comm-services-wrap {
  .service-icon-wrap {
    img {
      min-width: 75px;
      width: 75px;
    }

    p {
      font-size: 14px !important;
    }
  }

  .service-icon {
    background-color: #f5f4f8;
    border-radius: 12px;
    padding: 10px 16px;
    font-size: 16px;
    font-family: "lateral";
    font-weight: 700;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
  }
}

.scope-belt-wrap {
  .line-item-collection-header-div {
    border-bottom: 1px solid #cec9da !important;
  }

  .spec-table-wrap {
    @media screen and (max-width: 600px) {
      .desktop-view {
        display: none !important;
      }
      .mobile-view {
        display: block !important;
      }
    }

    .mobile-div {
      border-bottom: 1px solid rgba(30, 7, 88, 0.25);
      padding: 16px 8px;
    }

    .blue-span {
      color: #4d3a7d;
    }

    .table thead th,
    .table th,
    .table td {
      border-top: none;
      border-bottom: none;
    }

    thead tr {
      background-color: #f5f4f8;

      th {
        text-transform: uppercase;
        font-size: 12px !important;
        color: #4d3a7d;
      }

      th:first-child {
        border-radius: 12px 0 0 12px;
      }

      th:last-child {
        border-radius: 0px 12px 12px 0;
      }
    }

    .table tbody > tr > td {
      border-bottom: 1px solid #cec9da;
    }

    @include media-breakpoint-down(md) {
      overflow-x: auto;
    }
  }
}

.scopes-table-head {
  th {
    @include media-breakpoint-down(sm) {
      font-size: 14px !important;
    }
  }
}

.comm-scope-wrap {
  p {
    @include media-breakpoint-down(sm) {
      font-size: 14px !important;
    }
  }
}

.comm-features-wrap {
  img {
    min-width: 75px;
    width: 75px;
  }

  .flex-column {
    @include media-breakpoint-down(lg) {
      width: 33%;
    }

    @include media-breakpoint-down(sm) {
      width: 50%;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
}

.comm-quote-wrap {
  .table-responsive {
    thead tr {
      background-color: #f5f4f8;

      th {
        color: #4d3a7d;
        text-transform: uppercase;
        font-size: 12px !important;
        font-weight: 700;

        &:first-child {
          border-radius: 12px 0 0 12px;
        }

        &:last-child {
          border-radius: 0 12px 12px 0;
        }
      }
    }
  }
}

.comm-specification-wrap {
  .comm-spec-list {
    @include media-breakpoint-down(lg) {
      padding-left: 0;
    }

    @include media-breakpoint-down(md) {
      button {
        margin-top: 0 !important;
      }

      > div {
        margin-top: 10px;
      }
    }
  }
}
.repayment-info {
  background-color: #dff3fd;
}

.comm-quote-container {
  #authorise-work {
    .bg-white.p-3.m-5.col-12 {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
  }

  .comm-repayment-select {
    input[type="radio"] + span {
      padding: 0;
      color: #000;
    }

    input[type="radio"]:checked + span {
      padding: 0;
      color: #000;
      background-color: transparent;
    }

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
      padding-left: 10px;

      label {
        margin-top: 25px;
        margin-left: 0 !important;
      }
    }
  }
}

.footer-buttons-wrap {
  position: relative;

  .footer-call-btn-wrap {
    position: absolute;
    top: 0;
    right: -15px;
  }

  @include media-breakpoint-down(sm) {
    .footer-call-btn-wrap {
      top: 80px;
    }
  }
}

.comm-quote-wrap {
  .table thead th {
    border: none;
  }
}

.comm-prop-quote-table {
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
  }

  tbody tr td {
    border-top: 2px solid #c7c1d6;
    border-bottom: 2px solid #c7c1d6;
    vertical-align: middle;
    padding: 16px;

    &:first-child {
      border-radius: 12px 0 0 12px;
      border-left: 2px solid #c7c1d6;
    }

    &:last-child {
      border-radius: 0 12px 12px 0;
      border-right: 2px solid #c7c1d6;
    }
  }
}

.datepicker table tr td.disabled {
  background-color: #c2cfd6;
  opacity: 1;
  cursor: not-allowed;
  color: #3e515b;
  border-radius: 0;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background-color: #c2cfd6;
  cursor: not-allowed;
  color: #3e515b;
}

.grey-box-wrap {
  .col-6 {
    padding: 5px;
  }

  .grey-box {
    background-color: #f5f4f8;
    border-radius: 12px;
    padding: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      font-size: 14px;
      margin-bottom: 0px;
    }
  }
}

.quote-summary-box {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .quote-summary-wrap {
    justify-content: space-between;

    .quote-summary-box {
      width: 49%;
      margin-bottom: 0px !important;
    }
  }
}

.other-featured-projects {
  .col-12 {
    padding-top: 10px;

    .other-featured-projects-wrap {
      border-radius: 20px;
      height: 97%;

      .img-fluid {
        border-radius: 20px 20px 0px 0px;
      }
    }
  }
}

.testimonial {
  .testimonial-body {
    background-color: #f5f4f8;
    border-radius: 20px;

    .embed-responsive {
      border-radius: 20px;
    }

    .roch-commercial-testimonial {
      .embed-responsive {
        border-radius: 20px;
      }
    }

    .res-testimonial-copy {
      .copy-bg {
        border-radius: 0px 0px 20px 20px;
      }
    }
  }
}

.testimonial-link {
  background: none;
  border: none;
  border-bottom: 1px solid;
  font-size: 18px;
  padding: 0px;
  color: #1e0758;

  a:hover {
    text-decoration: none;
  }
}

.div-table-wrap {
  display: table;

  .dt-bottom {
    display: table-cell;
    vertical-align: bottom;
  }
}

.bottom-label-icons img {
  max-width: fit-content;
  max-height: 28px;
}

.img-fill-20 {
  max-height: 20px;
  max-width: 20px;
}

.img-fit-28 {
  max-height: 28px;
  max-width: fit-content;
}

.img-fit-32 {
  max-height: 32px;
  max-width: fit-content;
}
