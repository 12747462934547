// Fonts
@import url("https://fonts.googleapis.com/css?family=Sintony|Mr+Dafoe");

// Override Boostrap variables
@import "bootstrap-variables";

//select2
@import "~jquery-ui/themes/base/all.css";

// Simple Line Icons
@import "~simple-line-icons/scss/simple-line-icons";

@import "~element-ui/packages/theme-chalk/src/index";

// Slick and slick lightbox
@import "~slick-carousel/slick/slick.scss";

@import "~slick-carousel/slick/slick-theme.css";

@import "~slick-lightbox/dist/slick-lightbox.css";

// Timepicker
@import "~timepicker/jquery.timepicker.min.css";

// Datepicker
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";

//select2
@import "~select2/dist/css/select2.min.css";

// Global
@import "global";

// Variables
@import "pages/utilities/frontend-variables";

// Pages
@import "pages/pages";

// Bootstrap Overrides
@import "custom";

@import "loading";
// Plugins
