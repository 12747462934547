// Override the Bootstrap defaults without modifying key, versioned files.

// oh nonono
body {
  font-family: $master-font;
  color: $blue-primary;
  word-wrap: break-word;
  background-color: $gray-light;
}

.popup-color {
  color: $blue-primary;
}

.navbar-container {
  background-color: $gray-light;
}

.custom-navbar {
  background-color: $gray-light;
  height: 57px; /* was 40px */
}

.navbar-custom-list {
  background: $red-menu;
  width: 50%;
  max-width: 350px;
  min-width: 300px;
  /* margin-top:-3px; */
  padding: 30px;
}

.bg-none {
  background-color: transparent;
}

//utilities
.has-interaction,
.can-interact {
  cursor: pointer;
}

.icon {
  font-size: 30pt;
  color: $red-100;
}

// .text-red {
// 	color:red;
// }

.mt-minus-3 {
  margin-top: -3px;
}

// .red-text {
// 	color:red;
// }

// .p-bold {
// 	font-weight:bold;
// }

// .p-thin {
// 	font-weight:400;
// }

// .p-small {
// 	font-size:0.95em;
// }

// .open-sans {
// 	font-family:$secondary-font;
// }

// .desc-text {
// 	color:$description-text-color;
// }

.navbar-toggler {
  background-color: $red-menu;
}

.navbar-light .navbar-toggler {
  color: white;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1") !important;
}

.big {
  font-size: 350%;
}

.about-us {
  padding-bottom: 125px;
}

// .btn-accept	{
// 	background-color: rgba(129,190, 55, 1);
// 	color: white !important;
// }

// .btn-decline {
// 	color: grey;
// 	&:hover
// 	{
// 		background-color: red;
// 		color:white !important;
// 	}
// }

// .btn-comment {
// 	color: grey;
// 	&:hover
// 	{
// 		background-color: $blue;
// 		color:white !important;
// 	}
// }

.questions-container {
  min-height: 400px;
  background-image: url("/img/proposal/banner-footer.png");
  background-size: cover;
}

.swal2-popup {
  width: 75% !important;
  @media screen and (max-width: 767px) {
    width: 100% !important;
  }
}

.slick-lightbox-slick-caption {
  color: $blue-primary !important;
}

.swal-window-md {
  max-width: 500px !important;
}

//support for disabled/readonly inputs
.form-control:disabled,
.form-control[readonly] {
  cursor: not-allowed;
}

.feedback-header {
  height: 300px;
  background-image: url("/img/login/rochele-pattern-bg.png");
  background-size: cover;

  h1 {
    color: #fff;
  }
}

.roch-feedback-form {
  h2 {
    margin-bottom: 0;
    margin-right: 25px;
    color: $roch-dark-red;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
  }

  textarea {
    border-color: #102945;
    border-width: 1px;
    border-radius: 3px;
  }
}

/* see https://codepen.io/pezmotion/pen/RQERdm */
.feedback-rating {
  direction: rtl;
  unicode-bidi: bidi-override;
  color: $disabled-input-bg;

  .form-check-input {
    display: none;
  }

  label {
    cursor: pointer;
  }
}

.feedback-rating label:hover,
.feedback-rating label:hover ~ label,
.feedback-rating input:checked + label,
.feedback-rating input:checked + label ~ label {
  color: #ffd700;
  /* color: $roch-dark-blue;*/
}

/******************/
/* Repayment info */
.repayment-nav-tabs {
  input[type="radio"] + span {
    color: $roch-dark-blue;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid $roch-dark-blue;
  }

  input[type="radio"]:checked + span {
    color: #fff;
    background-color: $roch-dark-blue;
    border: 1px solid $roch-dark-blue;
  }
}

.repayment-info {
  .el-slider {
    .el-slider__bar {
      background-color: $roch-dark-blue;
    }

    .el-slider__button {
      border-color: $roch-dark-blue;
    }
  }
  background-color: #dff3fd;
}

/******************/
/* faceinput info */
.faceinput {
  /*
	img{
		padding: 4px;
	}
	input[type="radio"] + img {
		opacity: 0.4;
	}

	input[type="radio"]:checked + img {
		opacity: 1;
		border: 1px solid $roch-dark-blue;
	}
	*/
}

.timeline-icon-img {
  width: 40px;
}
.timeline-chat-badge {
  color: #b1b1b4 !important;
}

.worker-image {
  width: 75px;
  height: 75px;
  max-height: 75px;
  max-width: 75px;
  object-fit: contain;
}

.modal-open .twofa-blur {
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
}
