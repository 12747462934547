/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */
.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  margin: 0 auto 1em ;
  text-align: left;
}

.geosuggest__input {
  width: 100%;
  padding: .5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
          transition: border 0.2s, box-shadow 0.2s;
}

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
          transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: .5em .65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}

.roch-input {
  padding: 15px 25px !important; /* select 2 override */
  background: #fff;
  border: #999999 1px solid !important; /* select 2 override */
}

.booking-textarea {
  min-height: 170px;
}

.ui-timepicker-wrapper {
  width: 8.5em;
}

.select2-container--default .select2-selection--single {
  border-radius: 0;
  border: #999999 1px solid !important;
}

/* .select2-selection {
  padding: 15px 25px;
  background: #fff;
  border: #999999 1px solid !important;
  border-radius: 0 !important;
  width: 100% !important;
}

.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 61px;
} */