// Bootstrap overrides

//
// Color system
//
$simple-line-font-path: "/fonts/vendor/simple-line-icons/" !default;
$--font-path: "/fonts/vendor/element-ui/lib/theme-chalk";

$white: #fff;
$gray-100: #f0f3f5;
$gray-light: rgba(247, 248, 249, 1);
$gray-200: #c2cfd6;
$gray-300: #e3e3e3;
$gray-400: #869fac;
$gray-500: #678898;
$gray-600: #536c79;
$gray-700: #3e515b;
$gray-800: #29363d;
$gray-900: #151b1e;
$black: #000 !default;

$blue: #509bbc;
$blue-100: #0c2037;
$blue-primary: #1e0758;
$blue-primary-hover: #180647;
$blue-sidebar-active: #509bbc;
$blue-sidebar-hover: #3f536a;
$blue-secondary: #3393c9;
$blue-secondary-hover: #2287bf;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #f86c6b;
$red-sidebar-active: $blue-primary;
$red-100: #e34735;
$red-200: #dd5b57;
$red-menu: rgba(233, 49, 54, 1);
$orange: #f8cb00;
$yellow: #ffc107 !default;
$green: #4dbd74;
$teal: #20c997 !default;
$cyan: #63c2de;

$main-bg: #ffffff; /* was #F9F9F9; */

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
);

$theme-colors: (
  primary: $blue-primary,
  secondary: $gray-300,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
);

$description-text-color: #677781;
// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: false;

// Body
//
// Settings for the `<body>` element.

$body-bg: #e4e5e6;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 0.875rem;
$master-font: "lateral", sans-serif;
$secondary-font: "Open Sans", sans-serif;

// Breadcrumbs

$breadcrumb-bg: #fff;
$breadcrumb-margin-bottom: 1.5rem;

// Cards

$card-border-color: $gray-200;
$card-cap-bg: $gray-100;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;

// Buttons

$btn-secondary-border: $gray-300;

// Progress bars

$progress-bg: $gray-100;

// Tables

$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;

// Forms

$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;
