.res-testimonial-wrap {
  display: flex;
  flex-direction: column;
  .res-testimonial-copy {
    .copy-bg {
      display: flex;
      align-items: center;
      min-height: 102px;
      background-size: cover;
    }

    p {
      padding: 10px 84px 10px 10px;
      color: #fff;
    }
  }
}

.datepicker table tr td.disabled {
  background-color: #c2cfd6;
  opacity: 1;
  cursor: not-allowed;
  color: #3e515b;
  border-radius: 0;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background-color: #c2cfd6;
  cursor: not-allowed;
  color: #3e515b;
}
