// Bootstrap

// include libs
@import "~bootstrap/scss/bootstrap";

// Header Styles
.logged-in-as {
  margin: 0;
  border-radius: 0;
}

#radios label {
	cursor: pointer;
	position: relative;
}

#radios label + label {
	margin-left: 15px;
}

input[type="radio"] {
	opacity: 0; /* hidden but still tabable */
	position: absolute;
}

input[type="radio"] + span {
	color: #B3CEFB;
	border-radius: 5%;
	padding: 12px;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
}

input[type="radio"]:checked + span {
	color: #D9E7FD;
  background-color: #4285F4;
}

input[type="radio"]:focus + span {
	color: #fff;
}

.ui-timepicker-disabled {
	display: none !important;
}
@media only screen and (max-width: 992px) {
    .el-step__title {
		display:none !important;
	}
	.el-step__description{
		display:none !important;
	}
}
