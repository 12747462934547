.rochele-login {
  background-color: #f5f4f8;
}
.rochele-login-box {
  min-height: 100vh;
}
.logo {
  max-height: 136px;
}
.login-header {
  padding-top: 40px;
  background: #fff !important;
  padding-bottom: 40px;
}
.login-form {
  padding-left: 45px;
  padding-right: 45px;

  label {
    color: #2a2a2a;
  }

  .checkbox {
    label {
      color: $blue-primary;
    }
  }

  .form-control {
    border-color: #e3e3e3;
    border-radius: 5px;
  }
}
.btn-rochele {
  background-color: $blue-primary;
  border: 0;
  border-radius: 8px;
  font-weight: 700;
  &:hover {
    background-color: $blue-primary-hover;
  }
}
.card {
  border-radius: 16px;
  border: none;
}
.btn-lg {
  height: 40px;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
