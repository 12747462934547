// Font classes
$master-font: "lateral", sans-serif;
$body-font: "Open Sans", serif;

// site colors
$roch-dark-red: #e34735; // left side menu highlight, dividing line color
$roch-light-blue: #3393c9;
$roch-light-blue-hover: #2287bf;
$disabled-input-bg: #dbe2e6;

$even-row-color: #ffffff;
$odd-row-color: #f5f4f8;

// font colors
$roch-dark-blue: #1e0758; // heading/important font colors
$roch-com-dark-blue: #1e0758;

// Border radiuses
$b-rad-modal: 5px;
$b-rad-checkbox: 3px;
